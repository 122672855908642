exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-process-tsx": () => import("./../../../src/pages/our-process.tsx" /* webpackChunkName: "component---src-pages-our-process-tsx" */),
  "component---src-pages-our-works-tsx": () => import("./../../../src/pages/our-works.tsx" /* webpackChunkName: "component---src-pages-our-works-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-templates-project-project-tsx": () => import("./../../../src/templates/project/Project.tsx" /* webpackChunkName: "component---src-templates-project-project-tsx" */)
}

